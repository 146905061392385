/*.main-menu{
	z-index: 50;
	//position: fixed;
	//top: 0;
	//left: 0;
	background:$white;
	&__items {
		@include menu-base;
	}
}*/

.top-bar-container {
  z-index: 50;
  position: relative;
  .topbar__wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: white;
  }
}
.is-reveal-open {
  .top-bar-container {
    .topbar__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.menu-button {
  width: 40px;
  height: 40px;
  border-radius: 0;
  padding: 0;
  position: relative;
  right: 0;
  top: 3px;

  .menu-icon {
    position: absolute;
    left: 11px;
    top: 9px;
    span {
      opacity: 1;
      display: block;
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: $dark-gray;
      margin: 4px 0;
      @include transition(all);
    }
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 12px;
    color: $white;
    opacity: 0;
    @include transition(all);
    width: 16px;
    height: 16px;
    &.icon-cross {
      background-image: url('../images/icon-cross.svg');
      background-size: cover;
    }
  }
}