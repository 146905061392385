@each $colorName, $colorValue in $colors{
	.button--#{$colorName}{
		@include transition(all);
		padding: $button-padding;
		display: inline-block;
		@if $colorName == 'white' {
			color: $primary-color;
		}@else {
			background:$colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:#{$colorValue};
			@if $colorName == 'white' {
				color: $primary-color;
			}@else {
				color: $white;
			}
		}
		&.hollow {
			background: transparent !important;
			border-color: $colorValue;
			color: $colorValue;
			&:hover,&:focus {
				border-color: $colorValue;
				color: $colorValue;
			}
		}
	}
}