footer{
	background:$dark-gray;
	position: relative;
	z-index: 1;
	padding:rem-calc(73 0);
	width: 100%;
	@include text-spects{
		color:$white;
	}
	h4{
		text-transform: uppercase;
		margin-bottom: rem-calc(37px);
	}
	p{
		margin-bottom: rem-calc(28);
	}
	a{
		color:$white;
	}
	@include breakpoint(large only){
		.xlarge-order-4{
			padding-bottom: rem-calc(50px);
		}
	}
	ul{
		margin:0;
		padding:0;
		list-style-type: none;
		li{
			margin:rem-calc(0 0 2px);
			padding:0;
			color:$white;
		}
		&.social-links{
			padding:rem-calc(12px 0 0);
			@include breakpoint(large) {
				padding: rem-calc(65px 0 0);
			}
			li{
				display: inline-block;
				margin-right: rem-calc(16);
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.footer{
		&__logo{
			max-width: rem-calc(100px);
		}
	}
	@include breakpoint(large down){
		.grid-x>.cell{
			padding-bottom: rem-calc(50px);
			&:last-child{
				padding-bottom: 0;
			}
			&.small-order-2.large-order-1{
				padding-bottom: rem-calc(0);
			}
			&.small-order-1.large-order-2{
				padding-bottom: rem-calc(50px);
			}
		}
	}
}