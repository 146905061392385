.docs-nav{
	margin:0;
	padding:0;
	list-style: none;
	&__container{
		background: #f1f1f1;
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(50);
	}
	&__item{
		color:#444444;
		padding:rem-calc(10);
		display: block;
		font-weight: 600;
		&:focus{
			color:#444444;
		}
	}
	.submenu{
		list-style-type: none;
		a{
			font-weight: 400;
			color:#717171;
			display: block;
			padding: rem-calc(10 15);
			&.is-active{
				color: $primary-color;
			}
		}
	}
}
